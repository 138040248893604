/* Add these styles to PaymentWithUPI.css for any specific tweaks */
.paymentWithUPIContent {
  background: var(--containerColor);
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
}

.paymentWithUPIContent h1 {
  color: var(--textHeading);
}

.paymentWithUPIContent p {
  color: #aaa;
}

.card {
  background-color: var(--containerColor) !important;
  border-radius: 10px !important;
}

.input-group .form-control {
  background-color: transparent !important;
  border: 1px solid #444 !important;
}

.input-group .btn {
  border: 1px solid #444 !important;
  background: var(--blueColor);
  color: #000;
  margin-bottom: 0.5rem;
}

.custom-border {
  border-color: #ffffff3d !important;
}

.submitBtn {
  width: fit-content !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background: var(--blueColor);
  color: white;
  align-self: center;
}

.text-muted {
  font-size: 0.85rem;
}

.card .text-center {
  font-size: 0.9rem;
}

.card .text-warning {
  font-size: 0.85rem;
}

.addressContainer {
  display: flex;
  flex-direction: column;
}

.addressBar {
  display: flex;
  gap: 10px;
  align-items: center;
}

.qrCode {
  text-align: center;
}

#qrCodeBG {
  width: 190px;
  height: 190px;
  background: white;
  display: block;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}