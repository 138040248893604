/* src/components/Skeleton.css */
.mySkeleton {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: var(--gradientContainer);
    border-radius: 10px;
}

.mySkeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150%;
    height: 100%;
    width: 150%;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0));
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    100% {
        left: 100%;
    }
}