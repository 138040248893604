.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--gradientContainer);
    padding: 0px 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 60px;
    border-bottom: 1px solid #80808045;
}

.headerLogo {
    width: 150px;
}

.headerLogo img {
    width: 100%;
    filter: brightness(1.1);
}

.header .headerItems ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0;
}

.header .headerItems ul li {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    transition: 0.2s all;

}


.header .headerItems ul li::after {
    content: '';
    background: transparent;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all;
}

.header .headerItems ul li:hover::after {
    background: var(--blueColor);
    top: 35px;
}

.header .headerItems ul li:hover {
    color: var(--blueColor);
}

.headerLoginArea {
    display: flex;
    gap: 7px;
}

#bntPrimary,
#bntSecondary {
    padding: 8px 15px;
    border-radius: 5px;
    border: none;
    color: var(--btnText);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: 0.3s all;

}

#bntPrimary {
    background: var(--blueColor);
}

#bntSecondary {
    background: #F44336;
}

#bntPrimary:hover::after,
#bntSecondary:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.123) !important;
}

#bntPrimary:active,
#bntSecondary:active {
    transform: scale(0.8);
}

#headerBalance {
    display: flex;
    align-items: center;
    gap: 10px;

}

#headerBalance h1 {
    margin: 0;
    color: var(--textHeading);
    font-weight: 200;

}

#headerBalance img {
    width: 25px;
}

#headerBalance p {
    margin: 0;
    white-space: nowrap;
}

.headerDepositAndBalance {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.headerDepositAndBalanceItem {
    border-left: 1px solid #80808045;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

#headerDepositAndBalanceItemInner {
    display: flex;
    align-items: center;
    background: var(--bodyColor);
    padding: 0 2px 0 10px;
    border-radius: 40px;
    gap: 20px;
    height: 40px;
}

#depositButton {
    height: 36px;
    background: linear-gradient(45deg, #FED25F, #FFC72A);
    color: black;
    width: 90px;
    text-transform: uppercase;
}

#depositButton:active {
    background: #FED25F;
}

.headerLogoIcon {
    display: none;
}

.mobileHeader {
    display: none;
}

#menuIcon {
    color: var(--lightYellow);
    font-size: 25px;
    margin-left: 10px;
}

@media (max-width:1200px) {
    .mobileHeader {
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1px;
        z-index: 10;
        background: var(--bodyColor);
    }

    .mobileHeader>div {
        flex: 1 1;
        background: var(--containerColor);
        text-align: center;
        height: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s all;
    }

    .mobileHeader>div:active {
        background: var(--blueColor);
    }

    .mobileHeader>div i {
        color: var(--textHeading);
        font-size: 25px;
        height: 40px;
        display: block;
    }

    .mobileHeader>div p {
        margin: 0 0 10px 0;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        text-transform: uppercase;
    }

    .mobileHeader img {
        height: 40px;
    }

    .headerItems {
        display: none;
    }
}

@media (max-width:480px) {
    .mobileHeader>div p {
        margin: 0;
        font-size: 10px;
        margin-bottom: 5px;
        margin-top: 2px;
    }

    .headerDepositAndBalanceItem {
        padding: 0px 7px;
    }

    #headerDepositAndBalanceItemInner {
        display: flex;
        align-items: center;
        background: var(--bodyColor);
        padding: 0 5px 0 15px;
        border-radius: 40px;
        gap: 20px;
        height: 40px;
    }

    #depositButton {
        height: 30px;
        /* background: linear-gradient(45deg, #FED25F, #FFC72A); */
        background: (90deg, #96792d, #c5a454, #f8e895, #d9bb6e, #f3d67f, #a88d46);
        color: black;
        width: 70px;
        text-transform: uppercase;
    }
}

@media (max-width:900px) {
    .headerLogo {
        display: none;
    }

    .headerLogoIcon {
        display: block;
        width: 50px;
        object-fit: contain;
    }
}