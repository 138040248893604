.dashboardIncomeCard h3 {
    font-size: 12px;
    font-weight: 600;
    color: var(--brightGreen);
    margin: 0;
}

.dashboardIncomeCard {
    padding: 15px 20px;
    background: var(--containerColor);
    border-radius: 20px;
    border: var(--containerBorder);
    margin-bottom: 10px;
}

.dashboardIncomeCard h5 {
    font-size: 11px;
    margin: 0;
    text-transform: capitalize;
}

.dashboardIncomeCard h1 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: var(--colorPrimary);
}

.dashboardIncomeCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.dashboardIncomeCard h3 {
    font-size: 12px;
    font-weight: 600;
    color: var(--brightGreen);
    margin: 0;
}

.dashboardIncomeCard {
    padding: 15px 20px;
    background: var(--containerColor);
    border-radius: 20px;
    border: var(--containerBorder);
    margin-bottom: 10px;
}

.dashboardIncomeCard h5 {
    font-size: 11px;
    margin: 0;
    text-transform: capitalize;
}

.dashboardIncomeCard h1 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: var(--textHeading);
}

.dashboardIncomeCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.dashboardCardHeading span {
    color: var(--textHeading);
    margin-left: 10px;
    font-weight: bold;
}

.dashboardCardHeading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    display: flex;
    color: var(--textHeading);
}

@media (min-width: 1001px) {
    #dashboardRefLink {
        display: none;
    }
}

.dashboard {
    padding: 0px 30px;
    border-left: 0.5px solid rgba(182, 182, 182, 0.199);
    min-height: 90vh;
}

@media (max-width: 800px) {
    .dashboard {
        border: none;
        padding: 0;
    }

    .mb {
        margin-bottom: 20px !important;
        width: 100%;
    }

    .dashboardNewsCardRight {
        display: none;
    }

    .newsMobile {
        display: block;
    }

    .telegramBotDiv button {
        margin-top: 20px;
    }
}

@media (min-width: 800px) {
    .telegramBotDiv button {
        width: 160px;
        margin: auto;
        margin-right: 10px;
    }
}

.dashboardIncomeCard h3 {
    font-size: 12px;
    font-weight: 600;
    color: var(--textHeading);
    margin: 0;
}

@media (max-width: 1025px) {
    .mb-2 {
        width: 100% !important;
    }
}