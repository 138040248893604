.paymentProofText h5 {
    font-size: 34px;
    font-weight: 600;
    color: var(--textHeading);
    margin-bottom: 30px;
}

.paymentProofText p {
    color: var(--textHeading);
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.paymentProofData {
    width: 100%;
    max-width: 950px;
    margin: auto;
}

#uploadBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blueColor);
    border: none;
    padding: 5px 10px;
    gap: 10px;
    border-radius: 5px;
    transition: 0.1 all;
}

#uploadBtn:active {
    background: #2e8ae6;
}

#uploadBtn p {
    color: var(--textHeading);
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
}

#uploadBtn i {
    display: flex;
    color: var(--textHeading);
    font-size: 20px;
}