.myFooter {
    width: 100%;
    padding: 40px 20px;
    background: #152234;
    text-align: center;
    margin-top: 40px;
}

.footerSocialMedia {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.footerSocialMedia img {
    width: 40px;
}

#copyRightLine {
    color: var(--textLight);
    font-size: 12px;
    font-weight: 400;
    line-height: 1.166;
    margin: 0;
}

.footersLinks {
    display: flex;
    flex-direction: column;
    align-items: self-start;
}

.footersLinks a {
    margin-bottom: 10px;
    font-size: 13px;
    padding-left: 0;
    color: var(--textLight) !important;
    transition: 0.2s all;
    cursor: pointer;
}

.footersLinks a:hover {
    color: var(--textHeading) !important;
}

@media (max-width:1200px) {
    .myFooter {
        padding-bottom: 110px;
    }
}

#footerAdultLogo {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px
}

.footerPaymentMethods {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 30px;
}

.footerPaymentMethods div {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 140px;
    width: 100%;
}

.footerPaymentMethods img {
    object-fit: contain;
    width: 100%;
}

@media (max-width:992px) {
    #footerAdultLogo {
        justify-content: center;
        display: flex;
        margin: 30px 0;
        order: 1;

    }

    #adultLogoDown {
        order: 2;
    }
}