.dashboardBannerImage {
    width: 100%;
    height: 290px;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 100px;
}

.dashboardBannerImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width:480px) {
    .dashboardBannerImage {
        height: 120px;
    }
}

@media (max-width:380px) {
    .dashboardBannerImage {
        height: 150px;
    }
}

@media (max-width:768px) {
    .dashboardBannerImage {
        margin-top: 65px;
    }
}

@media (min-width:481) and (max-width:768px) {
    .dashboardBannerImage {
        height: 290px;
    }
}