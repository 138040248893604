.myProfile {
    padding: 20px 20px;
}

.myProfileInputField {
    background: var(--containerColor);
    position: relative;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 30px;
    padding: 0px 40px 0 20px;
}

.myProfileInputField select {
    background: transparent;
    width: 100%;
    border: none !important;
    outline: none !important;
    color: var(--textHeading);
}

.myProfileInputField select option {
    color: var(--textHeading);
    background: var(--containerColor);
}

#myProfileInputFieldTitle {
    position: absolute;
    top: -10px;
    left: 15px;
    background: #182436;
    font-weight: 300;
    right: unset !important;
    color: var(--textHeading);
    font-size: 13px;
    display: inline-block;
    line-height: .65;
    padding: 5px 10px;
    border-radius: 11px;
    max-width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    transform: none !important;
}

#myProfileInputFieldInput {
    background: transparent;
    border: none !important;
    outline: none !important;
    width: 100%;
    color: var(--textHeading);
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    caret-color: var(--textLight);
    border-color: transparent;
}

#myProfileInputFieldIcon {
    color: var(--textLight);
    font-size: 25px;
    position: absolute;
    right: 15px;
    top: 5px;
}

.myProfile .col-md-4 {
    margin-bottom: 30px !important;
}

.btnPrimaryMedium {
    background: linear-gradient(135deg, #0077f1, #0063c7 100%, #0063c7 0);
    color: var(--textHeading);
    font-weight: bold;
    padding: 14px 50px;
    min-height: 54px;
    border-radius: 27px;
    font-size: 16px;
    max-width: 330px;
    margin: 0 auto 24px;
    display: block;
    width: 100%;
    display: block;
    text-transform: uppercase;
    border-color: transparent;
    transition: 0.2s all;
    text-align: center;
}

.btnPrimaryMedium:active {
    transform: scale(0.95);
}