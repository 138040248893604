.gamesPlayer {
    min-height: 100vh;
}

.gamesPlayer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.iframe {
    width: 100%;
    min-height: 100vh;
    border: none;
    /* margin-top: 70px; */
}