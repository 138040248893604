/* Fund.css */
.dashboard {
  background-color: transparent;
  /* Adjusted for better visibility */
  color: #fff;
  padding: 20px;
}

.paymentContainer>div {
  flex-direction: column;
  display: flex;
  width: 50%;
  gap: 7px;
}

.paymentContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.planDiv {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  gap: 20px;
  /* Reduced gap for smaller screens */
  flex-direction: column;
}

.planDiv>h1 {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.inputPrimary {
  background-color: var(--containerColor);
  border: solid 1px #ffffff3d;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.inputPrimary:focus {
  border: 1px solid #00ffab;
  box-shadow: none;
}

.errorMsg {
  color: #ff4d4d;
  font-size: 12px;
  /* Adjusted font size for better readability */
}

/* .btnPrimary {
  background-color: #15e0cc;
  border: none;
  color: #000 !important;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
} */

.tab {
  padding: 10px;
  background-color: #3d3d3d;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.tab.active {
  background-color: #00ffab;
  color: #000;
}

.table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: separate;
}

th,
td {
  /* padding: 15px; */
  text-align: left;
  border-bottom: 1px solid #3d3d3d;
}

th {
  background-color: #3d3d3d;
  white-space: nowrap;
}

td {
  background-color: var(--containerColor);
}

@media (max-width: 768px) {
  .planDiv {
    gap: 10px;
    /* Reduced gap on smaller screens */
  }

  .inputPrimary {
    width: 100%;
    /* Ensures input takes full width */
  }

  /* .btnPrimary {
    width: 100%;
    text-align: center;
  } */

  .tab {
    width: 100%;
    margin: 5px 0;
  }

  .errorMsg {
    font-size: 10px;
    /* Adjusted font size for better fit on small screens */
  }
}

@media (max-width: 480px) {
  .planDiv {
    padding: 10px;
    /* Reduced padding for very small screens */
  }

  .paymentContainer>div {
    width: 100%;
  }

  .paymentContainer {
    flex-direction: column;
  }

  /* .btnPrimary {
    padding: 8px 16px;
    font-size: 14px;
  } */

  .inputPrimary {
    padding: 8px;
  }
}