.heading {
  color: var(--textHeading);
}
label {
  color: var(--textLight);
}
.form-control {
  background: transparent;
  outline: none;
  border: none;
  color: white !important;
}
.form-control:focus {
  background: transparent;
  outline: none;
  border: none;
  color: white !important;
  box-shadow: none;
}
.myProfileInputField input::placeholder {
  color: var(--textLight); /* Custom color for the placeholder */
  opacity: 1; /* Override default opacity */
}
