.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.dropzone {
    width: 100%;
    height: 200px;
    border: 2px dashed #007bff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    padding: 1rem;
}

.upload-button {
    padding: 0.5rem 1rem;
    background-color: var(--btnBg);
    color: var(--btnText);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    gap: 10px;
    display: flex;
}

.upload-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.error-message {
    color: red;
    margin-top: 1rem;
}

.previews {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropzone p {
    margin: 0;
    color: var(--textLight);
}

/* FileUpload.css */

.preview {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.remove-button {
    position: absolute;
    top: -11px;
    right: -11px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: red;
}