#profileHeaderHeading {
    width: 100%;
    background: var(--containerColor);
    padding: 32px;
    display: flex;
    align-items: center;
    gap: 5px;

}

#profileHeaderHeading i {
    color: var(--textHeading);
    font-size: 32px;
    display: flex;
}

#profileHeaderHeading h1 {
    color: var(--textHeading);
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0 !important;
    line-height: 1.25rem;
}

#profileHeaderTabs {
    background: var(--containerColor);
    width: 100%;
    padding: 10px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 -2px 3px rgba(0, 0, 0, .1);
}

#profileHeaderTabsLeft {
    display: flex;
    align-items: center;
    gap: 12px 25px;
    flex-wrap: wrap;
}

#profileHeaderTabsRight {
    display: flex;
    align-items: center;
    gap: 25px;
}

#profileHeaderTabs span {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--textLight);
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 10px 0;
    transition: all .3s ease-out;
    display: flex;
    gap: 2px;
}

#profileHeaderTabs span i {
    font-size: 20px;
    display: flex;
}

#profileHeaderTabs span:hover {
    color: var(--textHeading);
}

@media (max-width:400px) {
    #profileHeaderHeading h1 {
        font-size: 20px;
    }
}