.heading {
  color: var(--textHeading);
}
label {
  color: var(--textLight);
}
/* #betSelectBox {
  margin-left: auto !important;
  width: 200px !important;
} */
.block-btn {
  background: #0d6efd;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  color: white;
}
