.depositPaymentMethod {
    width: 100vw;
    height: 100vh;
    background: var(--gradientContainer);
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.depositPaymentMethodContent {
    width: 90%;
    max-width: 700px;
    background: #182233;
    max-height: 85vh;
    text-align: center;
    border: 1px solid #303f59;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

#depositPaymentMethodContentHeading {
    background: var(--containerColor);
    border-bottom: 1px solid #303f59;
    padding: 10px;
    position: relative;
    border-radius: 10px 10px 0 0;
}

#depositPaymentMethodContentHeading p {
    margin: 0;
    font-weight: 700;
}

#depositPaymentMethodContentSubHeading {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    line-height: 44px;
    color: var(--textHeading);
    font-weight: 700;
    font-size: 14px !important;
    padding: 10px;
}

.paymentsLogosSection {
    max-height: 70vh;
    /* Adjust height as needed */
    overflow-y: auto;
    padding: 13px;
}

.paymentsLogos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 110px;
    gap: 13px;
}

.paymentsLogos>div {
    border: 1px solid #303f59;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s all;
}

.paymentsLogos>div:hover {
    background: var(--gradientContainer);
}

#depositPaymentMethodContentHeading i {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--bodyColor);
    color: var(--textHeading);
    display: flex;
    align-items: center;
    justify-content: center;
    top: -15px;
    right: -15px;
    cursor: pointer;
}

.paymentsLogos img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.paymentsLogosCustomLogo>div>img {
    width: 60%;
    height: 60%;
}

#paymentBackButton {
    color: var(--textHeading);
    text-align: start;
    position: absolute;
    top: 13px;
    left: 13px;
    font-size: 25px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    transition: 0.2s all;
    cursor: pointer;
}

#paymentBackButton:active {
    color: var(--textLight);
}

.selectAmontBeforeTransaction {
    width: 100%;
    max-width: 400px;
    margin: auto;
    text-align: left;
}

.selectAmontBeforeTransaction h1 {
    color: var(--textHeading);
    font-size: 25px;
    font-weight: bold;
}

.selectAmontBeforeTransaction p {
    color: var(--textLight);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 3px;
}

.selectAmontBeforeTransaction p>h5 {
    margin: 0;
    font-size: 12px;
    color: rgb(4, 255, 222);
    font-weight: 600;
}

#selectAmontBeforeTransactionInput {
    position: relative;
}

#selectAmontBeforeTransactionInput input {
    background: var(--containerColor);
    width: 100%;
    padding: 10px 70px 10px 20px;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.329);
    outline: none;
    caret-color: var(--textHeading);
    color: var(--textHeading);
    margin: 5px 0;
}

#selectAmontBeforeTransactionInput span {
    position: absolute;
    top: 16px;
    right: 15px;
}

/* Hide the increase and decrease buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the arrows in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.chooseAmountFromTabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: 400px;
    margin: auto;
    gap: 10px;
}

.chooseAmountFromTabs span {
    border: 1px dotted #ccc;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s all;
}

.chooseAmountFromTabs span:active {
    background: rgba(128, 128, 128, 0.226);
}

.chooseAmountFromTabs span:hover {
    border: 1px dotted #05ffea;
}

/* Adjustments for responsiveness */
@media (max-width: 768px) {
    .chooseAmountFromTabs {
        grid-template-columns: repeat(2, 1fr);
    }
}