.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Darker semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px); /* Stronger blur effect */
    z-index: 1000;
}

.popup-content {
    background: linear-gradient(145deg, #2E2E2E, #1C1C1C); /* Subtle gradient background */
    color: #E0E0E0;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow */
    max-width: 400px;
    width: 100%;
    position: relative;
}

.popup-content h2 {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
}

.popup-close {
    position: absolute;
    top: 25px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.popup-close img {
    width: 30px;
    height: 30px;
}

.popup-confirm-button {
    background-color: #15E0CC; /* Vibrant confirm button */
    /* color: #fff; */
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
    align-self: center;
    font-family: "Poppins", sans-serif;
    transition: background-color 0.3s;
    border-radius: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
}

/* .popup-confirm-button:hover {
    background-color: #00b28d;
} */
