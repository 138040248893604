.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button {
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-controls span {
    margin: 0 10px;
}

#paginationButton span {
    color: var(--colorBlue) !important;
}