.withdrawalPage {
  width: 95%;
  max-width: 400px;
  background: var(--gradientContainer);
  padding: 20px;
  border-radius: 20px;
  display: block;
  margin: auto;
  margin-top: 30px;
}

.withdrawalPage label {
  color: var(--textLight);
  font-size: 12px;
  font-weight: 500;
}

.withdrawalPage button {
  margin: auto;
  margin-top: 20px !important;
}

.css-1nmdiq5-menu {
  color: black;
}

#betSelectBox {
  width: 100% !important;
}

.withdrawalAllOptions {
  width: 100%;
  max-width: 700px;
  margin: auto;
}