.providerGameSectionsGames {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* Adjust minmax value as needed */
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
    height: 100%;
}

.allGameThumbnail {
    width: 100%;
    height: 100%;
    overflow: hidden;
    aspect-ratio: 3 / 2;
}

.allGameThumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    transition: 0.3s all;
}

.providerGameSectionsGames div img:hover {
    transform: scale(1.1) !important;
}



@media screen and (max-width: 768px) {
    .providerGameSectionsGames {
        grid-template-columns: repeat(3, 1fr);
        /* Display one column */
    }
}