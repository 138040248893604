.homePage {
    /* background: var(--bodyColor); */
    position: relative;
    height: 100vh;
}

.iconHeading {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}

.iconHeading img {
    width: 28px;
}

.iconHeading p {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 16px;
}

.topGames {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 10px;
    margin-top: 20px;
}

.topGames>div {
    background: var(--gradientContainer);
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
}

#providerLogo {
    max-width: 50%;
    height: 40px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

#providerLogo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.topGames>div p {
    text-align: center;
    margin: 0;
}

.iconHeadingAndLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.iconHeadingAndLink .iconHeading {
    margin-top: 0 !important;
}

.headingViewLink h5 {
    font-size: 16px;
    color: rgb(255, 255, 255, 0.5);
    margin: 0;
}

.headingViewLink i {
    color: rgb(255, 255, 255, 0.5);
    display: flex;

}

.headingViewLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: 0.5s all;
}

.headingViewLink:hover {
    padding: 0;
    border-bottom: 1px solid rgb(255, 255, 255, 0.5);
}

.homeGameSections {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.homeGameSectionsLeft {
    width: 33%;
}

.homeGameSectionsRight {
    width: 67%;
}






/* ______________________________________________ */
.bgBlue {
    background: linear-gradient(135deg, #0097d8 0%, #17b5e7 53.13%, #0074df 100%);
}

.bgRed {
    background: linear-gradient(315deg, #900009 0%, #dd0035 100%);
}

.bgYellow {
    background: linear-gradient(135deg, #ff7a00 0%, #ffb800 57.81%, #ffdd2c 100%);
}

.homeGameSectionsDetailsDiv {
    position: relative;
    padding: 20px 10px 0 30px;
    border-radius: 10px;
    height: 100%;
}

.homeGameSectionsDetailsDiv #cardSmallHeading {
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    margin: 0;
}

.homeGameSectionsDetailsDiv #cardBigHeading {
    line-height: 40px;
    font-size: 31px;
    font-weight: 700;
}


.gameCardDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
}

.gameCardDetails>div {
    position: relative;
    width: 100%;
    padding: 12px 10px 12px 16px;
    border-radius: 12px;
    background: rgba(255, 255, 255, .7);
    backdrop-filter: blur(2px);
    margin-bottom: 5px;
    width: 70%;
    z-index: 2;
}

.gameCardDetails h5 {
    font-size: 20px;
    font-weight: 700;
    color: black;
}

.gameCardDetails p {
    margin-bottom: 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: black;
}

.gameCardDetails>div>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gameCardDetails>div>div>span {
    background: linear-gradient(135deg, #00AA58 0%, #007D3A 100%);
    color: var(--textHeading);
    padding: 4px;
    border-radius: 2px;
    font-size: 10px;
}

.cardImage1 {
    position: absolute;
    top: -3%;
    right: 0;
}

.cardImage2 {
    position: absolute;
    bottom: -5px;
    left: -14%;
}

/* .homeGameSectionsGames {
    padding: 0;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, max(200px));
    grid-auto-rows: 134px;
    gap: 13px;
    list-style: none;
} */

.homeGameSectionsGames {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns */
    grid-template-rows: repeat(3, 1fr);
    /* 3 rows */
    gap: 10px;
    /* Adjust the gap between items as needed */
    width: 100%;
    height: 100%;
}

.allGameThumbnail {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--gradientContainer);
    border-radius: 10px;
}

.allGameThumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    transition: 0.3s all;
}

.homeGameSectionsGames div img:hover {
    transform: scale(1.1) !important;
}

.bonusSection>div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.bonusSectionImageDiv {
    width: 300px;
    border-radius: 10px;
    overflow: hidden;
    transform: scale(1.0);
}

.bonusSectionImageDiv img {
    width: 100%;

}

.testimonialDiv {
    background: var(--containerColor);
    padding: 10px 20px;
    border-radius: 10px;
    margin: 10px;
}

.testimonialDiv>div>img {
    width: 70px;
}

.testimonialDiv>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.testimonialDiv>div h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.testimonialDiv p {
    font-size: 14px;
    margin-top: 20px;
}

#ratingDiv {
    display: flex;
    gap: 5px;
}

#ratingDiv img {
    width: 20px;
}

@media (max-width:1200px) {
    .topGames {
        margin-top: 10px;
    }

    .topGames div p {
        font-size: 10px;
        font-weight: 700;
        white-space: nowrap;
    }

    .homeGameSectionsLeft {
        display: none;
    }

    .homeGameSectionsRight {
        width: 100%;
    }

    .iconHeading p {
        font-size: 15px;
    }

    .headingViewLink h5 {
        font-size: 14px;
    }

    .homeGameSectionsRight {
        overflow-x: auto;
        white-space: nowrap;
    }

    .homeGameSectionsGames {
        display: flex;
    }

    .homeGameSectionsGames div {
        flex: 0 0 auto;
        width: 175px;
    }

    .homeGameSectionsRight::-webkit-scrollbar {
        display: none;
    }

    .iconHeading {
        margin-top: 10px;
    }

    .iconHeadingAndLink {
        margin-top: 20px;
    }
}

.referAndEarnLeft img {
    width: 100%;
}

.referAndEarn {
    position: relative;
    width: 100%;
    padding: 20px;
    backdrop-filter: blur(100px);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    margin-top: 20px;
}

.referAndEarn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('./../../Assets/Images/effect.jpg'); */
    background-color: var(--containerColor);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    filter: blur(25px);
}

.referAndEarnRight {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 20px;
}

.referAndEarnRight>p {
    color: var(--textHeading);
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
}

.referAndEarnRight>div {
    border: 1px dotted var(--textHeading);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 20px;
    border-radius: 10px;
    background: var(--blueColor);
}

.referAndEarnRight>div>p {
    margin: 0;
    font-size: 14px;
}

.referAndEarnRight>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    border-left: 1px dotted var(--textHeading);
    cursor: copy;

}


.referAndEarnRight>div>div>i {
    color: var(--textHeading);
}

.referAndEarnRight>div>div>p {
    color: var(--textHeading);
    font-size: 10px;
    margin: 0;
}



.referAndEarnRight>h5 {
    color: var(--textHeading);
    font-size: 16px;
    text-align: center;
}



@media (max-width:768px) {
    .referAndEarnRight>p {
        margin-top: 30px;
    }

    .topGames {
        gap: 10px;
    }

    .topGames>div {
        width: 100%;
        flex: auto;
        padding: 7px;
    }

    .homeGameSections {
        margin-top: 10px;
    }

    .homeGameSectionsGames div {
        width: 170px;
    }

    .topGames {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 10px;
        margin-top: 20px;
    }
}