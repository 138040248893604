/* General Styles */
.fund-history-section {
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.filter-input-section input {
  background-color: #333333;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.filter-input-section button {
  background-color: #3c9ff6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.history-heading {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.history-table-wrapper {
  overflow-x: auto;
  border-radius: 10px;
  background: var(--containerColor);
  padding: 20px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
  /* Ensures table has a minimum width for better scrolling on small screens */
}

.history-table th,
.history-table td {
  padding: 10px 20px;
  text-align: center;
  white-space: nowrap;
  /* Prevent wrapping of text */
}

.history-table th {
  background-color: var(--containerColor);
  font-weight: 600;
}

.history-table td {
  background-color: var(--containerColor);
}

.history-table .pending {
  color: #ffce2e;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
}

.history-table .approved {
  color: #00db28;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500
}

.history-table .rejected {
  color: "red";
}

.status-btn {
  background: var(--blueColor);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: White !important;
  font-weight: 500;
  font-size: 14px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .fund-history-section {
    padding: 10px 0px;
  }

  .filter-section button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .filter-input-section input {
    padding: 8px;
    font-size: 14px;
  }

  .filter-input-section button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .history-heading {
    font-size: 20px;
    text-align: center;
  }

  .history-table-wrapper {
    padding: 10px;
    overflow-x: auto;
    /* Ensures table is scrollable horizontally */
  }

  .history-table {
    min-width: 100%;
    /* Ensure table takes full width */
  }

  .history-table th,
  .history-table td {
    padding: 8px 10px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    /* Prevent wrapping of text */
  }

  .status-btn {
    padding: 6px 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .fund-history-section {
    padding: 5px 0px;
  }

  .filter-section button,
  .filter-input-section input,
  .filter-input-section button {
    font-size: 12px;
    padding: 6px 10px;
  }

  .history-heading {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
  }

  .history-table-wrapper {
    padding: 8px;
    overflow-x: auto;
  }

  .history-table th,
  .history-table td {
    padding: 6px 8px;
    font-size: 12px;
  }

  .status-btn {
    padding: 4px 8px;
    font-size: 12px;
  }
}