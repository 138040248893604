.paymentHistoryTabs {
    background: var(--bodyColor);
    text-align: center;
    height: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.185);
}

.paymentHistoryTabs>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.paymentHistoryTabs>div h1 {
    margin: 0;
    color: var(--textHeading);
    transition: all .3s ease-out;
    line-height: 1.2;
    font-size: 14px;
    font-weight: 700;
    padding: 0 1px 18px;
    margin-left: 14px;
    margin-right: 14px;
    letter-spacing: unset;
    text-transform: none;
    padding: 0;
    position: relative;
    transition: 1s all;
    cursor: pointer;
}

.paymentHistoryTabs .activePaymentHistoryTab::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: var(--blueColor);
    top: 33px;
    left: 0;
}

.paymentHistoryBody {
    padding: 20px;
}

.depositHistoryCard {
    background: var(--containerColor);
    border-left: 4px solid rgba(0, 195, 255, 0.055);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 0px;
}

.depositHistoryCard #depositHistoryCardTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.depositHistoryCard #depositHistoryCardTop p {
    color: #7ebffb;
    font-size: 14px;
    margin: 0;
}

.depositHistoryCard #depositHistoryCardTop h5 {
    background: #dd2c00;
    color: var(--textHeading);
    text-transform: uppercase;
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    margin: 0;
}

.depositHistoryCard #depositHistoryCardHeading {
    font-size: 21px;
    font-weight: 700;
    color: var(--textHeading);
    margin: 17px 0 10px 0;
}

.depositHistoryCard #depositHistoryCardDetails p {
    font-size: 14px;
    display: grid;
    color: #6b7c98;
    margin: 0;
    font-weight: 400;
    word-break: break-all;
}