#adult {
    display: block;
    margin: auto;
    width: 80px;
    margin-top: 20px;
    /* opacity: 0.3; */
}

.registerSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: #0e1724e5;
    padding: 40px;
    display: flex;
    justify-content: center;
}

#registerCrossIcon {
    color: white;
}

.registerData {
    width: 67%;
    height: 100%;
    display: flex;
    border-radius: 17px;
    overflow: hidden;
    animation: popup 0.2s ease-out;
}

.registerLeft {
    width: 50%;
    background: #152234;
    height: 100%;
    overflow: hidden;
    position: relative;
    /* background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 20px 60px 20px;
    align-items: center; */
}

/* .registerLeft img {
    width: 150px !important;
} */

.registerLeft h1 {
    font-size: 40px !important;
    color: var(--textHeading);
    text-shadow: 0 4px 8px rgba(0, 0, 0, .32);
    text-transform: uppercase;
    font-family: Poppins;
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: center;
}

.registerLeft p {
    color: var(--textHeading);
    text-shadow: 0 4px 8px rgba(0, 0, 0, .32);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}


.registerLeft>img {
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.registerLeftContent {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 0), rgba(128, 128, 128, 0));
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.registerLeftContent img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

.registerRight {
    width: 50%;
    background: #152234;
    height: 100%;
    overflow-y: scroll;
}

.registerRight::-webkit-scrollbar {
    display: none;
}

.registerRight {
    padding: 20px 25px;
}

#registerGiftDiv {
    background: var(--containerColor);
    border-radius: 10px;
}


#registerGiftDivIcon {
    display: flex;
    align-items: center;
    justify-content: start;
    background: var(--containerColor);
    width: 100%;
    border-radius: 10px;
}

#registerGiftDivIcon p {
    align-self: center;
    max-width: 150px;
    color: #ffb131;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.6;
    text-transform: uppercase;
    margin: 0;
}

#registerGiftDivIcon>div {
    background: #ffb131;
    -webkit-box-shadow: 0 10px 20px rgba(252, 175, 48, .2);
    box-shadow: 0 10px 20px rgba(252, 175, 48, .2);
    border-radius: 8px;
    margin-right: 14px;
    height: 58px;
    width: 65px;
    text-align: center;
}

#registerGiftDivIcon>div>img {
    background-position: center center;
    background-size: contain;
    height: 56px;
}

#registerMobileDiv {
    display: flex;
    background: var(--containerColor) !important;
    align-items: center;
    border-radius: 8px;
    padding: 11px;
}

#registerMobileDiv .PhoneInput {
    width: 100%;
}

#registerMobileDiv .PhoneInput input {
    background: transparent;
    border: none;
    outline: none;
    color: white;
}


#registerPasswordDiv {
    position: relative;
}

#registerPasswordDiv i {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
}

#registerPasswordDiv i svg {
    fill: var(--textLight);
    transition: 0.2s fill;
}

#registerPasswordDiv i:hover svg {
    fill: white;
}

#registerPromoCode {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

#registerPromoCode p {
    margin: 0;
}

#registerPromoCode i {
    display: flex;
    font-size: 20px;
    color: white;
    background: var(--blueColor);
    border-radius: 50%;
}

.registerApplyCode {
    position: relative !important;
}

.registerApplyCode input {
    padding-right: 100px;
}

.registerApplyCode button {
    position: absolute;
    top: 3px;
    right: 4px;
    border: none;
    background: rgba(255, 255, 255, .12);
    color: white;
    padding: 9px 15px;
    border-radius: 25px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    width: 80px;
}

.registerApplyCode input::placeholder {
    color: var(--textLight);
}

.modal__form-title {
    margin-top: 16px;
    margin-bottom: 16px;
    color: rgba(255, 255, 255, .7);
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.375rem;
}

.registerSection .css-1dimb5e-singleValue {
    color: white;
}

.registerTermCondition input {
    margin-right: 10px;
}

.registerTermCondition {
    color: #5c7391;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    user-select: none;
    margin-top: 25px;
}

.registerTermCondition a {
    color: white;
    text-decoration: underline !important;
    cursor: pointer;
}

.alreadyRegister {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.alreadyRegister p {
    margin: 0;
    color: #5c7391;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    user-select: none;
    margin-right: 5px;
}

.alreadyRegister a {
    color: white;
}

@keyframes popup {
    0% {
        transform: scale(0.1);
    }

    100% {
        transform: scale(1);
    }
}

@media (max-width: 1200px) {
    .registerLeft {
        display: none;
    }

    .registerRight {
        width: 100%;
        padding: 20px 10px;
        max-height: max-content;
        margin: auto;
    }

    .registerData {
        width: 100%;
    }

    .registerSection {
        padding: 40px 10px;
    }
}