@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --bodyColor: #0c1930;
  --containerColor: #1c2d44;
  --gradientContainer: linear-gradient(180deg, #18263a 0%, rgba(24, 38, 58, 0.6) 100%);
  --blueColor: #1976D2;
  --textHeading: #fff;
  --btnText: #fff;
  --btnBg: #007bff;
  --textLight: #859cba;
  --lightYellow: #FED056;
}

html {
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
h4,
h5,
span,
li {
  margin: 0;
  color: #ffffff;
}

body,
html {
  padding: 0;
  margin: 0;
  background: var(--bodyColor) !important;
  position: relative;
}

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  list-style: none;
  /* user-select: none; */
}

.btnPrimary {
  width: 100%;
  padding: 12px;
  background: var(--blueColor);
  color: var(--btnText);
  border: none;
  border-radius: 30px;
  font-weight: 700;
  font-size: 13px;
}

::-webkit-scrollbar {
  background-color: var(--bodyColor);
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgb(40, 40, 59);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(81, 81, 114);
}

.mainInput {
  width: 100%;
  padding: 11px 28px;
  border-radius: 8px;
  outline: none;
  background: var(--containerColor);
  color: var(--textHeading);
  font-size: 14px;
  border: none;
}

.mainInput::placeholder {
  color: var(--textLight);
  font-size: 14px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.formError {
  color: rgb(255, 39, 39);
  font-size: 12px;
  margin: 0;
}

.container {
  max-width: 1275px !important;
}

.mainContent {
  margin-top: 60px;
  margin-bottom: 100px;
}

#providerSkeleton {
  padding: 0 !important;
  height: 100px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background: var(--bodyColor) !important;
  color: var(--textHeading) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: var(--containerColor) !important;
}

.copied-animation {
  color: white;
  font-size: 14px;
  margin-left: 10px;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.skiptranslate {
  /* display: none !important */
}

@media (max-width:1200px) {
  .container {
    padding: 5px !important;
  }
}

@media (max-width:767px) {
  .btnPrimary {
    width: 100%;
    padding: 12px;
    font-size: 10px;
  }

  #providerSkeleton {
    height: 60px;
  }
}