.paymentWithBankContent {
    width: 100%;
    max-width: 500px;
    margin: auto;
    background: var(--gradientContainer);
    padding: 20px;
    border-radius: 10px;
}

#paymentWithBankImage {
    display: block;
    width: 200px;
    margin: 10px auto 20px auto;
}

.paymentWithBankContent>h5 {
    font-size: 14px;
    color: #419efc;
    margin: 10px 0 20px 0;
}

.bankitems {
    margin-top: 5px;
}

.bankitems label {
    width: 100%;
    color: var(--textLight);
    font-size: 11px;
    font-weight: 500;
}

.bankitems input {
    margin-bottom: 5px;
    color: #ccc;
    background: transparent;
    width: 100%;
    padding: 7px 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.35);
    padding-left: 50px;
    font-size: 12px;
}

.bankItemsInput {
    position: relative;
}

.bankItemsInput i {
    position: absolute;
    color: var(--textLight);
    left: 10px;
    top: -2px;
    font-size: 22px;
}