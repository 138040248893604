.incomeSelectBox select {
  background: var(--containerColor);
  padding: 10px;
  border-radius: 20px;
  color: var(--textHeading);
  display: block;
}
.incomeContent {
  padding: 20px;
}
.incomeSelectBox option {
  padding: 10px;
}

.incomeSelectBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap-reverse;
  gap: 20px;
}

.incomeSelectBox button {
  color: var(--textHeading);
  background: transparent;
  border: 1px solid var(--textHeading);
  padding: 10px 30px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.incomeSelectBox button i {
  font-size: 24px;
  display: flex;
  margin-left: 10px;
}

.filtersection {
  background: var(--containerColor);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.filtersectionBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
}

.filtersection select {
  background: var(--containerColor);
  padding: 10px;
  border-radius: 5px;
  color: var(--textHeading);
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}

.filtersection button {
  color: white;
  background: var(--colorPrimary);
  padding: 8px 30px;
  border-radius: 5px;
  border: transparent;
  width: 100%;
}
thead tr th {
  text-transform: uppercase;
  line-height: 15px;
  font-size: 12px;
  font-weight: 400;
  padding-right: 50px;
  padding: 10px;
}
table {
  width: 100%;
}
.table {
  width: 100%;
  overflow-x: auto;
  padding: 30px 0;
}

.table tr {
  border: none !important;
  background: transparent;
  color: var(--textLight);
}

.table th {
  color: black;
  font-size: 14px;
  font-weight: 600;
  background: #ffc72a;
  border: 1px solid var(--colorPrimary);
  text-align: center;
  padding: 10px 20px;
  white-space: nowrap;
}

.table p {
  text-align: center;
  margin: 50px auto;
  color: var(--textColor);
}

.table td {
  color: var(--textColor);
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border: 1px solid rgba(128, 128, 128, 0.171);
  white-space: nowrap;
}

@media (max-width: 767px) {
  .incomeSelectBox button,
  .incomeSelectBox select {
    width: 100%;
  }
}
